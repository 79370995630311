/* eslint-disable react/prop-types */
/* ========================================================= * Material Kit 2 PRO React - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/material-kit-pro-react * Copyright 2021 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

// react
import { useState } from 'react';

// next
import dynamic from 'next/dynamic';

// PRO
const MDBox = dynamic(() => import('components/MDBox'), { loading: () => null });

// MUI
const Card = dynamic(() => import('@mui/material/Card'), { loading: () => null });

function RotatingCard({ children }) {
  const [rotate, setRotate] = useState(false);
  return (
    <MDBox sx={{ perspective: '50rem' }} onMouseEnter={() => setRotate(true)} onMouseLeave={() => setRotate(false)}>
      <Card
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          position: 'relative',
          transform: rotate ? 'rotateY(180deg)' : 'rotateY(0)',
          transformStyle: 'preserve-3d',
          transition: 'all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1)',
        }}
      >
        {children}
      </Card>
    </MDBox>
  );
}

export default RotatingCard;
